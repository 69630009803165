import logo from './logo.svg';
import './App.css';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies, setCookie] = useCookies(['jwt']);

  const redirectWithCookies = ()=>{

    setCookie('jwt', 'MichaelLAUUUUUUUUUUU', { path: '/' });
    
    window.location.href = "https://hahaha.michael-testing.com/";


  }


  

  return (
    <div className="App">
      <header className="App-header">
       <p>This is another page with this Cookies</p>
{cookies.jwt}
      </header>
    </div>
  );
}

export default App;
